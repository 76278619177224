import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export interface ContentProps {
  children: React.ReactNode;
  style?: any;
  class?: any;
}

export const Content = (props: ContentProps) => (
  <div className={classNames(styles.wrapper, props.class)} style={props.style}>
    {props.children}
  </div>
);
