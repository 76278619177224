import Footer from '@layout/footer';
import NavHomeTop from '@layout/nav-home-top';
import Button from '@uikit/button';
import { Content } from '@uikit/content';
import { LayoutContainer } from '@uikit/layout/container';
import Lottie from 'lottie-web';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import styles from './styles.module.scss';

export default function Page404() {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let lottie1: any = null;

    if (ref.current) {
      lottie1 = Lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: `/lottie/404.json`
      });
    }

    return () => {
      lottie1?.destroy();
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <NavHomeTop />
      <Content>
        <LayoutContainer>
          <div className={styles.errorPageContent}>
            <div ref={ref} className={styles.errorPageLottie} />
            <h2>Page not found</h2>
            <p>We {`can't`} seem to find the page you are looking for.</p>
            <Button type={'white'} onClick={() => router.push('/')}>
              Back to homepage
            </Button>
          </div>
        </LayoutContainer>
      </Content>

      <Footer />

      <img src="/img/bottom.png" alt="" className={styles.imgBottom} />
    </div>
  );
}
